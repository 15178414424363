import React, { useEffect, useRef, useState } from 'react';
import { FC } from 'react';
import { Button, Typography } from '@material-ui/core';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

const GetApp: FC = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button title="Install app" onClick={handleClickOpen}>
        Install App
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          <img
            src="/static/images/getApp.png"
            alt="Get App"
            style={{ width: '100%' }}
          />
        </DialogContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography style={{ marginLeft: 25, fontSize: 12 }}>
            <a
              href="https://support.google.com/chrome/answer/9658361?hl=en&co=GENIE.Platform%3DiOS&oco=0"
              target="_blank"
            >
              Learn more about Progressive Web Apps (PWA)
            </a>
          </Typography>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default GetApp;
